import '../styles/global.css';

import classNames from 'classnames';
import { PageProps } from 'gatsby';
import { FC } from 'react';

import {
  Footer,
  Header,
  RequestPanel,
  TypicalDocumentsPanel,
} from '../components';

const Layout: FC<PageProps> = ({ children, ...props }) => {
  return (
    <>
      <div
        className={classNames(
          'flex',
          'flex-col',
          'min-h-screen',
          'font-body',
          'bg-page',
          'text-graphite-90',
          'tracking-[-0.3px]',
          'sm:tracking-[-0.5px]',
          'text-[16px]',
          'sm:text-[20px]',
          'lg:text-[24px]',
          'leading-[20px]',
          'sm:leading-[25px]',
          'lg:leading-[32px]'
        )}
      >
        <Header location={props.location} />
        <main className={classNames('flex-grow', 'overflow-hidden')}>
          {children}
        </main>
        <Footer />
      </div>
      <RequestPanel />
      <TypicalDocumentsPanel />
    </>
  );
};

export default Layout;
