import classNames from 'classnames';
import { FC } from 'react';

import { Image } from '../Image';
import { Slide, Slider } from '../Slider';
import { Trans } from '../Trans';
import * as styles from './styles.module.css';

interface IMassMediaSliderProps {
  data: { image?: string; text?: string; url?: string }[];
  variant?: 'basis';
}

export const MassMediaSlider: FC<IMassMediaSliderProps> = (props) => {
  const { data, variant } = props;
  const isBasis = variant === 'basis';

  return (
    <Slider
      className={classNames(
        styles.slider,
        isBasis && styles.sliderBasis,
        'sm:flex',
        'sm:justify-center'
      )}
      options={{
        perPage: 1,
        arrows: false,
        autoWidth: true,
        focus: 'center',
        trimSpace: true,
        drag: true,
        gap: 0,
      }}
    >
      {data.map(({ image, text, url }, i) => (
        <Slide key={`${url}_${i}`} className={classNames('flex', styles.slide)}>
          <div className={classNames(styles.slideContent, 'flex')}>
            <div
              className={classNames(
                isBasis ? 'bg-graphite-30' : 'bg-orange-35',
                'rounded-[8px]',
                'pt-[12px]',
                'xs:pt-[17px]',
                'lg:pt-[32px]',
                'xl:pt-[30px]',
                'pb-[50px]',
                'xs:pb-[62px]',
                'sm:pb-[45px]',
                'lg:pb-[65px]',
                'px-[18px]',
                'xs:px-[30px]',
                'lg:px-[40px]',
                'flex',
                'flex-auto',
                'flex-col',
                'relative'
              )}
            >
              <Image
                className={classNames('h-[44px]', i ? 'w-auto' : 'w-[48%]')}
                objectFit="contain"
                objectPosition="left"
                src={image || ''}
                alt={''}
              />
              <div
                className={classNames(
                  'mt-[8px]',
                  'lg:mt-[22px]',
                  'sm:text-[18px]',
                  'sm:leading-[23px]',
                  'lg:text-[20px]',
                  'lg:leading-[25px]',
                  'lg:max-w-[310px]'
                )}
              >
                <Trans>{text}</Trans>
              </div>
              <a
                href={url}
                target="_blank"
                className={classNames('absolute', 'inset-0')}
              >
                &nbsp;
              </a>
            </div>
          </div>
        </Slide>
      ))}
    </Slider>
  );
};
