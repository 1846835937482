import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { GatsbyImageProps } from 'gatsby-plugin-image/dist/src/components/gatsby-image.browser';
import { FC } from 'react';

export interface IImageProps extends Omit<GatsbyImageProps, 'image'> {
  src: string;
  className?: string;
  [name: string]: any;
}

export const Image: FC<IImageProps> = ({
  src,
  objectFit,
  objectPosition,
  onClick = () => undefined,
  onDragStart,
  alt = '',
  ...props
}) => {
  const { allImageSharp, svgFiles } = useStaticQuery(graphql`
    query {
      allImageSharp {
        edges {
          node {
            gatsbyImageData(placeholder: NONE)
            parent {
              ... on File {
                publicURL
              }
            }
          }
        }
      }
      svgFiles: allFile(filter: { extension: { eq: "svg" } }) {
        edges {
          node {
            base
            publicURL
          }
        }
      }
    }
  `);

  const bitmapIMG = allImageSharp?.['edges'].find(
    (element) =>
      element?.['node']?.['gatsbyImageData']?.['images']?.['fallback']?.['src']
        .split('/')
        .pop() === src
  );

  const vectorIMG = svgFiles?.['edges'].find(
    (element) => element?.['node']?.['base'] === src
  );

  if (!bitmapIMG && !vectorIMG) {
    return null;
  }

  const isVector = Boolean(vectorIMG);

  if (isVector) {
    return (
      <img {...props} src={vectorIMG?.['node']?.['publicURL']} alt={alt} />
    );
  }

  const handleImageClick = () => {
    onClick(bitmapIMG.node.parent.publicURL);
  };

  const handleDragStart = (e) => {
    onDragStart(e, bitmapIMG.node.parent.publicURL);
  };

  return (
    <GatsbyImage
      {...props}
      alt={alt}
      onClick={handleImageClick}
      onDragStart={handleDragStart}
      image={bitmapIMG?.['node']?.['gatsbyImageData']}
      objectFit={objectFit}
      objectPosition={objectPosition}
      backgroundColor="transparent"
    />
  );
};
