import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import En from './en/translation.json';
import Ru from './ru/translation.json';

export const LOCALES = {
  en: 'en',
  ru: 'ru'
};

i18n.use(initReactI18next).init({
  lng: LOCALES.ru,
  fallbackLng: LOCALES.ru,
  supportedLngs: Object.values(LOCALES),
  returnObjects: true,
  react: {
    useSuspense: false,
  },
  resources: {
    [LOCALES.ru]: { translation: Ru },
    [LOCALES.en]: { translation: En }
  },
  interpolation: {
    escapeValue: false
  }
});

export default i18n;
