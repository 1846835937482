import { declareAtom } from '@reatom/core';

import { closeRequestPanelAction, openRequestPanelAction } from './actions';
import { NAME } from './contstants';

const initialState = { isOpen: false, isSuccess: false };

export const requestPanelAtom = declareAtom(
  `${NAME}Atom`,
  initialState,
  (on) => [
    on(openRequestPanelAction, (state, isSuccess = false) => {
      return {
        ...state,
        isSuccess,
        isOpen: true,
      };
    }),
    on(closeRequestPanelAction, () => initialState),
  ]
);
