import { useAction } from '@reatom/react';
import classNames from 'classnames';
import { FC, useCallback } from 'react';
import { Trans as DefaultTrans } from 'react-i18next';

import {
  requestPanelActions,
  typicalDocumentsPanelActions,
} from '../../components';
import { Link } from '../../ui';

type ILinkPatchProps = {
  onClick?: () => void;
};

const LinkPatch: FC<ILinkPatchProps> = (props) => {
  return (
    <Link
      className={classNames(
        'text-orange-60',
        'hover:underline',
        'cursor-pointer'
      )}
      {...props}
    />
  );
};

export const Trans: FC = (props) => {
  const openRequestPanelAction = useAction(
    requestPanelActions.openRequestPanelAction
  );
  const openTypicalDocumentsPanelAction = useAction(
    typicalDocumentsPanelActions.openTypicalDocumentsPanelAction
  );

  const handleOpenRequestPanel = useCallback(
    () => openRequestPanelAction(),
    [openRequestPanelAction]
  );

  const handleOpenTypicalDocumentsPanel = useCallback(
    () => openTypicalDocumentsPanelAction(),
    [openTypicalDocumentsPanelAction]
  );

  return (
    <DefaultTrans
      components={{
        OpenRequestPanel: <LinkPatch onClick={handleOpenRequestPanel} />,
        OpenTypicalDocumentsPanel: (
          <LinkPatch onClick={handleOpenTypicalDocumentsPanel} />
        ),
        Link: <LinkPatch />,
      }}
      {...props}
    />
  );
};
