import { useAction, useAtom } from '@reatom/react';
import classNames from 'classnames';
import { FC, useCallback } from 'react';

import { useTranslateData } from '../../../hooks';
import { SendRequest, SvgIcon, Trans } from '../../index';
import * as actions from '../actions';
import * as atoms from '../atoms';
import * as styles from './styles.module.css';

export const Form: FC = () => {
  const { form, success } = useTranslateData('components.requestPanel');
  const { isSuccess } = useAtom(atoms.requestPanelAtom);
  const openAction = useAction(actions.openRequestPanelAction);

  const handleSuccess = useCallback(() => {
    openAction(true);
  }, []);

  if (isSuccess) {
    return (
      <div className={classNames('text-center', 'pt-[85px]', 'lg:pt-[164px]')}>
        <div
          className={classNames(
            'flex',
            'justify-center',
            'text-orange-60',
            'mb-[24px]'
          )}
        >
          <SvgIcon
            name="circle_done"
            className={classNames(
              'h-[36px]',
              'w-[36px]',
              'lg:w-[55px]',
              'lg:h-[55px]'
            )}
          />
        </div>

        <div
          className={classNames(
            'font-bold',
            'text-[23px]',
            'leading-[28px]',
            'tracking-[-0.6px]',
            'lg:text-[40px]',
            'lg:leading-[49px]',
            'lg:tracking-[-1.3px]',
            'mb-[8px]'
          )}
        >
          <Trans>{success?.title}</Trans>
        </div>
        <div
          className={classNames(
            'text-[16px]',
            'leading-[20px]',
            'tracking-[-0.6px]',
            'lg:text-[20px]',
            'lg:leading-[25px]',
            'lg:tracking-[-0.5px]',
            'mb-[26px]',
            'lg:mb-[34px]'
          )}
        >
          <Trans>{success?.description}</Trans>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className={classNames(
          'font-bold',
          'text-[23px]',
          'leading-[28px]',
          'tracking-[-0.6px]',
          'lg:text-[40px]',
          'lg:leading-[49px]',
          'lg:tracking-[-1.3px]',
          'mb-[8px]'
        )}
      >
        <Trans>{form?.title}</Trans>
      </div>
      <div
        className={classNames(
          'text-[16px]',
          'leading-[20px]',
          'tracking-[-0.6px]',
          'lg:text-[20px]',
          'lg:leading-[25px]',
          'lg:tracking-[-0.5px]',
          'mb-[26px]',
          'lg:mb-[34px]'
        )}
      >
        <Trans>{form?.description}</Trans>
      </div>
      <SendRequest
        formClassName={styles.form}
        controlContainerClassName={styles.controlContainer}
        submitControlClassName={styles.submit}
        agreementClassName={styles.agreement}
        onSuccess={handleSuccess}
      />
    </>
  );
};
