import { TFunctionResult, TOptions } from 'i18next';
import { TFuncReturn, useTranslation } from 'react-i18next';

export const useTranslateData = <Data extends TFunctionResult>(
  key: string,
  options?: TOptions<{ [key: string]: any }>
): TFuncReturn<string, string, Data | TFunctionResult> => {
  const { t, ready } = useTranslation();

  if (ready) {
    return t(key, options);
  }

  return null;
};
