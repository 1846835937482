import { declareAction } from '@reatom/core';

import { NAME } from './contstants';

export const openTypicalDocumentsPanelAction = declareAction(
  `${NAME}OpenAction`
);

export const closeTypicalDocumentsPanelAction = declareAction(
  `${NAME}CloseAction`
);
