import classNames from 'classnames';
import { Form, Formik } from 'formik';
import { FC, useCallback, useState } from 'react';
import * as Yup from 'yup';

import { requestApi } from '../../api';
import { useTranslateData } from '../../hooks';
import { Button, Input, Link, Textarea } from '../../ui';
import { sendAnalyticEvent } from '../../utils/analytics';
import * as styles from './styles.module.css';

const schema = Yup.object().shape({
  name: Yup.string().default('').required('Укажите ваше имя'),
  company: Yup.string().default('').required('Укажите название компании'),
  phone: Yup.string().default('').required('Укажите ваш телефон')
    .matches(
      /(^((8|\+7)[- ]?)?(\(?\d{3}\)?[- ]?)?[\d\- ]{6,10}$)/,
      'Неверный формат телефона'
    ),
  email: Yup.string().default('').required('Укажите вашу почту')
    .matches(
      /(.+@[a-zа-яё-]{2,}\.[a-zа-яё]{2,})/,
      'Неверный формат почты'
    ),
  telegram: Yup.string().default(''),
  additional: Yup.string().default(''),
});

interface ISendRequestProps {
  variant?: 'dark' | boolean;
  formClassName?: string;
  controlContainerClassName?: string;
  submitControlClassName?: string;
  agreementClassName?: string;
  onSuccess?: () => void;
  onFail?: () => void;
}

export const SendRequest: FC<ISendRequestProps> = (props) => {
  const {
    variant,
    formClassName,
    controlContainerClassName,
    submitControlClassName,
    agreementClassName,
    onSuccess,
    onFail,
    ...rest
  } = props;
  const { name, phone, email, telegram, company, additional, submit, description, agreement, errorMessage } =
    useTranslateData('components.sendRequest');
  const [hasError, setHasError] = useState(false);

  const isDark = variant === 'dark';

  const handleSubmit = useCallback((data) => {
    return requestApi
      .create(data)
      .then((data) => {
        setHasError(false);

        if (onSuccess) {
          onSuccess(data);
        }
        sendAnalyticEvent('forma');
      })
      .catch((error) => {
        setHasError(true);
        if (onFail) {
          onFail(error);
        }
      });
  }, []);

  return (
    <div {...rest}>
      <Formik
        initialValues={schema.cast()}
        validationSchema={schema}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting }) => (
          <Form
            className={classNames(
              formClassName,
              'flex',
              'flex-col',
              'space-y-[12px]'
            )}
            name="sendRequest"
          >
            <div
              className={classNames(
                controlContainerClassName,
                'space-y-[10px]',
                'lg:flex',
                'lg:space-y-0',
                'lg:space-x-[10px]',
              )}
            >
              <div className={classNames('lg:flex-grow')}>
                <Input label={name?.label} name="name" borderless={isDark} />
              </div>
              <div className={classNames('lg:flex-grow')}>
                <Input label={company?.label} name="company" borderless={isDark} />
              </div>
              <div className={classNames('lg:flex-grow')}>
                <Input label={phone?.label} name="phone" borderless={isDark} />
              </div>
              <div className={classNames('lg:flex-grow')}>
                <Input label={email?.label} name="email" borderless={isDark} />
              </div>
            </div>
            <div
              className={classNames(
                controlContainerClassName,
                'space-y-[10px]',
                'lg:flex',
                'lg:space-y-0',
                'lg:space-x-[10px]',
              )}
            >
              <div className={classNames('lg:flex-grow', isDark ? 'lg:w-1/4' : '')}>
                <Input label={telegram?.label} name="telegram" borderless={isDark} />
              </div>
              <div className={classNames('lg:flex-grow', isDark ? 'lg:w-[calc(50%+12px)]' : '')}>
                <Textarea label={additional?.label} name="additional" borderless={isDark} />
              </div>
              <Button
                type="submit"
                name="submitRequest"
                className={classNames(
                  submitControlClassName,
                  'mt-[20px]',
                  'lg:mt-[0px]',
                  'w-full',
                  isDark ? 'lg:w-1/4' : '',
                  styles.submit,
                  isDark && styles.submitDark,
                )}
                disabled={isSubmitting}
              >
                {submit?.label}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {hasError && (
        <div
          className={classNames(
            'mt-[10px]',
            'mb-[13px]',
            'text-[14px]',
            'leading-[18px]',
            'text-center',
            'text-red-90'
          )}
        >
          {errorMessage}
        </div>
      )}
      <div
        className={classNames(
          agreementClassName,
          'mt-[14px]',
          'lg:mt-[17px]',
          'text-[14px]',
          'leading-[18px]',
          'text-center',
          'lg:text-left',
          'lg:text-[16px]',
          'lg:leading-[20px]'
        )}
      >
        {description}{' '}
        <Link
          to={agreement?.url}
          className={classNames('hover:underline', !isDark && 'text-orange-60')}
        >
          {agreement?.text}
        </Link>
      </div>
    </div>
  );
};
