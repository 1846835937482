import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { AnimatePresence, motion } from 'framer-motion';
import { FC, useRef, useState } from 'react';

import * as styles from './styles.module.css';

interface ITextareaProps {
  className?: string;
  label?: string;
  borderless?: boolean;
}

export const Textarea: FC<ITextareaProps> = ({
  className,
  label,
  borderless,
  ...props
}) => {
  const textareaRef = useRef(null);
  const { isSubmitting } = useFormikContext();
  const [currentValidationValue, setCurrentValidationValue] =
    useState<string>('');
  const [field, meta] = useField(props);
  const { value } = field;
  const hasValue = value === 0 || Boolean(value);
  const { error, touched } = meta;
  const hasError =
    Boolean(error) &&
    touched &&
    textareaRef.current?.value === currentValidationValue;

  const handleBlurInput = () => {
    setCurrentValidationValue(textareaRef.current.value);
  };

  return (
    <div>
      <label
        className={classNames(
          'relative',
          'block',
          'text-[18px]',
          'leading-none',
          'tracking-[-0.58px]'
        )}
      >
        <textarea
          ref={textareaRef}
          // onFocus={handleFocusInput}
          className={classNames(
            className,
            styles.control,
            hasValue && styles.controlHasValue,
            'outline-none',
            'w-full',
            'h-[100px]',
            borderless ? 'lg:h-[50px]' : '',
            'rounded-[6px]',
            'px-[16px]',
            'leading-[21px]',
            'text-graphite-90',
            'pt-[22px]',
            'bg-white',
            'border',
            'transition-colors',
            'resize-none',
            hasError
              ? 'border-red-90'
              : borderless
              ? 'border-white'
              : 'border-graphite-50',
            isSubmitting && 'cursor-not-allowed'
          )}
          disabled={isSubmitting}
          {...field}
          {...props}
          onBlur={handleBlurInput}
        />
        <span
          className={classNames(
            styles.label,
            'absolute',
            'text-graphite-60',
            'left-[16px]',
            'top-[16px]',
            'leading-[21px]',
            'transition-transform',
            'transform',
            'origin-top-left'
          )}
        >
          {label}
        </span>
      </label>
      <AnimatePresence>
        {hasError && (
          <motion.div
            initial="hidden"
            animate="visible"
            exit="hidden"
            variants={{
              visible: {
                y: 0,
                opacity: 1,
                height: 'auto',
              },
              hidden: {
                y: '-50%',
                opacity: 0,
                height: 0,
              },
            }}
          >
            <div
              className={classNames(
                'text-red-90',
                'text-[14px]',
                'leading-[16px]',
                'pt-[6px]'
              )}
            >
              {error}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
