import classNames from 'classnames';
import { motion, useTransform, useViewportScroll } from 'framer-motion';
import { FC } from 'react';

import { useBreakpoint } from '../../hooks';
import { Image } from '../Image';
import * as styles from './styles.module.css';

const breakpoints = ['lg', 'xl'];

export const DocumentList: FC = () => {
  const { scrollYProgress } = useViewportScroll();
  const { breakpoint } = useBreakpoint();
  const scale = useTransform(scrollYProgress, (v) => {
    const realValue = v * 80;
    const defaultValue = 1;
    const min = 1;
    const max = 3;

    if (!breakpoints.includes(breakpoint as string)) {
      return defaultValue;
    }

    return realValue <= min ? min : realValue >= max ? max : realValue;
  });

  return (
    <div>
      <motion.div
        className={classNames('relative', 'origin-center', 'transform')}
        style={{
          // @ts-ignore
          '--tw-scale-x': scale,
          '--tw-scale-y': scale,
        }}
      >
        <div
          className={classNames(
            'absolute',
            'bottom-0',
            'transform',
            'translate-y-1/2',
            'w-full',
            'flex',
            'justify-center',
            'items-center'
          )}
        >
          <div
            className={classNames(styles.item, styles.itemLarge, 'mt-[-2%]')}
          >
            <Image src="doc_inn.png" alt="doc" />
          </div>
          <div
            className={classNames(styles.item, styles.itemSmall, 'mt-[-8.5%]')}
          >
            <Image src="doc_passport.png" alt="doc_passport" />
            <Image src="doc_tk.png" alt="doc_tk" />
          </div>
          <div
            className={classNames(styles.item, styles.itemMedium, 'mt-[-6%]')}
          >
            <Image src="doc_schetf_ru.png" alt="schetfru" />
          </div>
          <div
            className={classNames(styles.item, styles.itemLarge, 'mt-[-6%]')}
          >
            <Image src="doc_svobr.png" alt="svobr" />
          </div>
          <div
            className={classNames(styles.item, styles.itemSmall, 'mt-[-10%]')}
          >
            <Image src="doc_diplom.png" alt="diplom" />
            <Image src="doc_vb.png" alt="vb" />
          </div>
          <div
            className={classNames(styles.item, styles.itemSmall, 'mt-[-13.5%]')}
          >
            <Image src="doc_tk.png" alt="tk" />
          </div>
          <div
            className={classNames(styles.item, styles.itemMedium, 'mt-[-6%]')}
          >
            <Image src="doc_nakl_ru.png" alt="nakladnayaru" />
            <Image src="doc_schetf.png" alt="schetf" />
          </div>
          <div
            className={classNames(styles.item, styles.itemLarge, 'mt-[-16.5%]')}
          >
            <Image src="doc_inn.png" alt="inn" />
          </div>
          <div
            className={classNames(styles.item, styles.itemSmall, 'mt-[-22%]')}
          >
            <Image src="doc_snils.png" alt="snils" />
            <Image src="doc_diplom.png" alt="diplom" />
            <Image src="doc_passport.png" alt="passport" />
          </div>
          <div
            className={classNames(styles.item, styles.itemLarge, 'mt-[-31%]')}
          >
            <Image src="doc_taxinv.png" alt="taxinv" />
            <Image src="doc_nakladnaya.png" alt="nakladnaya" />
          </div>
          <div
            className={classNames(styles.item, styles.itemSmall, 'mt-[-34.5%]')}
          >
            <Image src="doc_passport.png" alt="passport" />
            <Image src="doc_vb.png" alt="vb" />
            <Image src="doc_tk.png" alt="tk" />
          </div>
        </div>
      </motion.div>
    </div>
  );
};
