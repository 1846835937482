import {
  ReactEventHandler,
  ReactNode,
  RefObject,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';

type IHandleGetVideoBgColor = (
  event: SyntheticEvent<HTMLVideoElement, Event>
) => string;

type ITarget = HTMLVideoElement;

const handleGetVideoBgColor: IHandleGetVideoBgColor = ({ target }) => {
  const { offsetHeight, offsetWidth } = target as ITarget;
  const canvas = document.createElement('canvas');
  const canvasCtx = canvas.getContext('2d');

  canvas.height = offsetHeight;
  canvas.width = offsetWidth;

  canvas.style.setProperty('position', 'absolute');
  canvas.style.setProperty('opacity', '0');

  (target as ITarget).parentNode?.insertBefore(canvas, target as ITarget);
  canvasCtx?.drawImage(target as ITarget, 0, 0, offsetWidth, offsetHeight);

  const p = canvasCtx?.getImageData(0, 0, 8, 8).data;
  const color = p ? `rgb(${p[60]}, ${p[61]}, ${p[62]})` : '';

  canvas.remove();

  return color;
};

export const useVideoBg = <T = ReactNode>(
  ref: RefObject<T>,
  callback: (color: string) => void,
  specifiedColor?: string
): void => {
  const [color, setColor] = useState<string | null>();

  const handleSetColor: ReactEventHandler<HTMLVideoElement> = useCallback(
    (event) => {
      const { target } = event;

      if ((target as ITarget).offsetParent) {
        const color = specifiedColor ?? handleGetVideoBgColor(event);
        setColor(color);
      }
    },
    []
  );

  const bindEvents = useCallback((element) => {
    element.addEventListener('canplaythrough', handleSetColor);
  }, []);

  const unBindEvents = useCallback((element) => {
    element.removeEventListener('canplaythrough', handleSetColor);
  }, []);

  useEffect(() => {
    if (color) {
      return;
    }

    const video = ref.current;

    bindEvents(video);

    return () => {
      unBindEvents(video);
    };
  }, [bindEvents, unBindEvents]);

  useEffect(() => {
    if (color) {
      const video = ref.current;

      unBindEvents(video);

      if (callback) {
        callback(color);
      }
    }
  }, [color]);
};
