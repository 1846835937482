import classNames from 'classnames';
import { ElementType, forwardRef } from 'react';

type ISize = 1 | 2 | 3 | 4 | 5 | 6 | number;

interface IHeadlineProps {
  as?: ElementType;
  size?: ISize;
  className?: string;
  children?: React.ReactNode;
}

type IStyleMap = Record<ISize, string[]>;

const styleMap: IStyleMap = {
  1: [
    'text-[24px]',
    'xs:text-[30px]',
    'sm:text-[45px]',
    'lg:text-[65px]',
    'leading-[28px]',
    'xs:leading-[35px]',
    'sm:leading-[49px]',
    'lg:leading-[75px]',
    'xl:leading-[80px]',
    'xs:tracking-[-1px]',
    'sm:tracking-[-1.3px]',
    'lg:tracking-[-1.7px]',
  ],
  2: [
    'text-[24px]',
    'xs:text-[30px]',
    'sm:text-[40px]',
    'lg:text-[60px]',
    'leading-[28px]',
    'xs:leading-[35px]',
    'sm:leading-[49px]',
    'lg:leading-[70px]',
    'xs:tracking-[-1px]',
    'sm:tracking-[-1.3px]',
    'lg:tracking-[-1.6px]',
  ],
  3: [
    'text-[20px]',
    'xs:text-[20px]',
    'sm:text-[20px]',
    'lg:text-[20px]',
    'leading-[25px]',
    'xs:leading-[25px]',
    'sm:leading-[25px]',
    'lg:leading-[25px]',
    'xs:tracking-[-0.4px]',
    'sm:tracking-[-0.4px]',
    'lg:tracking-[-0.4px]',
  ],
  4: [
    'text-[18px]',
    'sm:text-[30px]',
    'leading-[21px]',
    'sm:leading-[35px]',
    'tracking-[-0.4px]',
    'sm:tracking-[-1px]',
  ],
  6: [
    'text-[12px]',
    'sm:text-[16px]',
    'lg:text-[25px]',
    'xl:text-[30px]',
    'leading-none',
    'tracking-normal',
  ],
};

export const Headline = forwardRef<HTMLElement, IHeadlineProps>(
  ({ as, size = 1, className, children }, ref) => {
    const tagName = `h${size}`;
    const Tag = as || tagName;
    const defStyles = styleMap[size].filter(el => !className?.split(' ').some(cn => cn.startsWith(el.replace(/^(.*-\[)([a-z\d.-]+\])$/, '$1'))));

    return (
      <Tag
        ref={ref}
        className={classNames(
          defStyles,
          'font-bold',
          'tracking-[-0.6px]',
          className
        )}
      >
        {children}
      </Tag>
    );
  }
);
