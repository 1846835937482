import Account from '../../assets/icons/account.svg';
import ArrowPrice from '../../assets/icons/arrow_price.svg';
import ArrowUp from '../../assets/icons/arrow_up.svg';
import Bank from '../../assets/icons/bank.svg';
import Chart from '../../assets/icons/chart.svg';
import Check from '../../assets/icons/check.svg';
import CircleDone from '../../assets/icons/circle_done.svg';
import Client from '../../assets/icons/client.svg';
import Close from '../../assets/icons/close.svg';
import Cloud from '../../assets/icons/cloud.svg';
import CRM from '../../assets/icons/crm.svg';
import Documents from '../../assets/icons/documents.svg';
import Education from '../../assets/icons/education.svg';
import Emblem from '../../assets/icons/emblem.svg';
import Gos from '../../assets/icons/gos.svg';
import Insurance from '../../assets/icons/insurance.svg';
import It from '../../assets/icons/it.svg';
import Laptop from '../../assets/icons/laptop.svg';
import Lawyer from '../../assets/icons/lawyer.svg';
import Like from '../../assets/icons/like.svg';
import Logo from '../../assets/icons/logo.svg';
import MenuClosed from '../../assets/icons/menu_closed.svg';
import MoneyBag from '../../assets/icons/money_bag.svg';
import Money from '../../assets/icons/money.svg';
import Number1 from '../../assets/icons/number_1.svg';
import Number2 from '../../assets/icons/number_2.svg';
import Number3 from '../../assets/icons/number_3.svg';
import Percent99 from '../../assets/icons/percent_99.svg';
import Profile from '../../assets/icons/profile.svg';
import RealEstate from '../../assets/icons/real_estate.svg';
import RepeatCycle from '../../assets/icons/repeat_cycle.svg';
import Rocket from '../../assets/icons/rocket.svg';
import RoundTheClock from '../../assets/icons/round_the_clock.svg';
import RoundedArrowUp from '../../assets/icons/rounded_arrow_up.svg';
import Scanner from '../../assets/icons/scanner.svg';
import Seconds12 from '../../assets/icons/seconds_12.svg';
import Servers from '../../assets/icons/servers.svg';
import Shopping from '../../assets/icons/shopping.svg';
import Spinner from '../../assets/icons/spinner.svg';
import Star from '../../assets/icons/star.svg';
import Taxi from '../../assets/icons/taxi.svg';
import Timer from '../../assets/icons/timer.svg';
import Tourism from '../../assets/icons/tourism.svg';
import Warning from '../../assets/icons/warning.svg';

export const iconMap = {
  arrow_up: ArrowUp,
  arrow_price: ArrowPrice,
  laptop: Laptop,
  documents: Documents,
  like: Like,
  logo: Logo,
  money: Money,
  percent_99: Percent99,
  number_1: Number1,
  number_2: Number2,
  number_3: Number3,
  profile: Profile,
  round_the_clock: RoundTheClock,
  scanner: Scanner,
  rounded_arrow_up: RoundedArrowUp,
  timer: Timer,
  repeat_cycle: RepeatCycle,
  bank: Bank,
  account: Account,
  gos: Gos,
  it: It,
  lawyer: Lawyer,
  education: Education,
  taxi: Taxi,
  insurance: Insurance,
  crm: CRM,
  tourism: Tourism,
  shopping: Shopping,
  real_estate: RealEstate,
  client: Client,
  star: Star,
  chart: Chart,
  rocket: Rocket,
  money_bag: MoneyBag,
  seconds_12: Seconds12,
  emblem: Emblem,
  cloud: Cloud,
  servers: Servers,
  check: Check,
  close: Close,
  menu_closed: MenuClosed,
  circle_done: CircleDone,
  spinner: Spinner,
  warning: Warning,
};
