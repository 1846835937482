import classNames from 'classnames';
import { navigate } from 'gatsby';
import {
  AnchorLink as GatsbyLink,
  AnchorLinkProps,
} from 'gatsby-plugin-anchor-links';
import { ButtonHTMLAttributes, FC } from 'react';

interface ILinkProps extends ButtonHTMLAttributes<HTMLAnchorElement> {
  to?: string;
  className?: string;
  download?: boolean;
  inNewTab?: boolean;
  specialCase?: boolean;
}

export const Link: FC<ILinkProps & AnchorLinkProps> = ({
  to,
  className,
  children,
  inNewTab,
  specialCase = false,
  ...props
}) => {
  const hasLink = Boolean(to);
  const linkProps = {
    ...props,
    className: classNames(className, hasLink && 'hover:underline'),
  };

  const specialNavigate = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ): void => {
    event.preventDefault();
    const [pathName, hash] = to.split('#');
    if (hash) {
      navigate(pathName, {
        replace: true,
        state: { ...history.state, hash },
      });
      return;
    }
    navigate(to);
  };

  if (hasLink && to) {
    const isInternal = /^\/(?!\/)/.test(to);
    const isFile = /\.[0-9a-z]+$/i.test(to);

    if (isFile) {
      return (
        <a href={to} target="_blank" {...linkProps}>
          {children}
        </a>
      );
    }

    if (isInternal && to && !inNewTab) {
      if (specialCase) {
        return (
          <a
            href={to}
            rel="noopener noreferrer"
            {...linkProps}
            onClick={specialNavigate}
          >
            {children}
          </a>
        );
      }

      return (
        <GatsbyLink to={to} {...linkProps}>
          {children}
        </GatsbyLink>
      );
    }

    return (
      <a href={to} target="_blank" rel="noopener noreferrer" {...linkProps}>
        {children}
      </a>
    );
  }

  return <span {...linkProps}>{children}</span>;
};
