import { createStore } from '@reatom/core';
import { connectReduxDevtools } from '@reatom/debug';
import { context } from '@reatom/react';
import { FC, useEffect } from 'react';

import { MediaQueryProvider } from '../hooks/useMediaQuery';

export const Provider: FC = ({ children }) => {
  const store = createStore();

  useEffect(() => connectReduxDevtools(store), []);

  return (
    <context.Provider value={store}>
      <MediaQueryProvider>
        {children}
      </MediaQueryProvider>
    </context.Provider>
  );
};
