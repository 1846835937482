import {
  ForwardedRef,
  ReactNode,
  Ref,
  RefObject,
  useEffect,
  useRef,
} from 'react';

export const useCombinedRefs = <T = ReactNode>(
  ...refs: (Ref<ReactNode> | ForwardedRef<ReactNode>)[]
): RefObject<T> => {
  const targetRef = useRef<T>(null);

  useEffect(() => {
    refs.forEach((ref) => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        (ref as React.MutableRefObject<ReactNode>).current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
};
