import React, { createContext, FunctionComponent, useContext, useEffect, useState } from 'react';

import data from '../data/styles-vars.json';
import { isBrowser } from '../utils/is-browser';

const mediaQueryHandlers = Object.entries(data.mediaQueries).map(([name, value]) => ({
  name,
  list: isBrowser() ? window.matchMedia(value) : null,
  listener: null,
}));

const mediaQueryInitialSatte = mediaQueryHandlers.reduce((obj, { name, list }) => ({
  ...obj,
  [name]: isBrowser() ? list.matches : null
}), {});

const MediaQueryContext = createContext(mediaQueryInitialSatte);

export const MediaQueryProvider:FunctionComponent<any> = ({ children }) => {
  if (!isBrowser()) {
    return children;
  }

  let mounted = false;
  const [mediaQuery, setMediaQuery] = useState(mediaQueryInitialSatte);

  const handleChange = (name:string, ev:any) => {
    if (!mounted) return;
    setMediaQuery(prevMediaQuery => ({ ...prevMediaQuery, [name]: ev.matches }));
  };

  useEffect(() => {
    mounted = true;
    mediaQueryHandlers.forEach(handler => {
      const listener = (ev:any) => handleChange(handler.name, ev);
      handler.listener = listener;
      handler.list.addListener(listener);
    });

    return () => {
      mounted = false;
      mediaQueryHandlers.forEach(({ list, listener }) => list.removeListener(listener));
    };
  }, []);

  return (
    <MediaQueryContext.Provider value={mediaQuery}>
      { children }
    </MediaQueryContext.Provider>
  );
};

export const useMediaQuery = ():Record<string, boolean> => useContext(MediaQueryContext);
