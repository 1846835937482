import basisDocs from '../../assets/video/basisDocs.mp4';
import cube from '../../assets/video/cube.mp4';
import cube2 from '../../assets/video/cube2.mp4';

export const videoMap: IVideoMap = {
  basisDocs,
  cube,
  cube2,
};

export type IVideoMap = {
  basisDocs: string;
  cube: string;
  cube2: string;
};
