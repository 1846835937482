import { Splide } from '@splidejs/react-splide';
import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';

import { isBrowser } from '../../utils/is-browser';

type ISplideOptions = {
  perPage?: number;
  arrows?: boolean;
  autoWidth?: boolean;
  focus?: string;
  trimSpace?: boolean;
  drag?: boolean;
  gap?: number;
  breakpoints?: Record<string | number, Omit<ISplideOptions, 'breakpoints'>>;
};

type PropsWithoutRef = {
  className?: string;
  children?: React.ReactNode;
  options: ISplideOptions;
  onActive?: (_: any, { index }: any) => void;
};

type Props = ForwardRefExoticComponent<
  PropsWithoutRef & RefAttributes<unknown>
>;

export const Slider: Props = forwardRef((props, ref) => {
  return isBrowser() ? <Splide ref={ref} {...props} /> : null;
});
