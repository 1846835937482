import { useAction, useAtom } from '@reatom/react';
import { FC, useCallback } from 'react';

import { SidePanel } from '../index';
import * as actions from './actions';
import * as atoms from './atoms';
import { Form } from './Form';

export const RequestPanel: FC = () => {
  const { isOpen } = useAtom(atoms.requestPanelAtom);
  const closeAction = useAction(actions.closeRequestPanelAction);

  const handleClose = useCallback(() => {
    closeAction();
  }, [closeAction]);

  return (
    <SidePanel isOpen={isOpen} handleClose={handleClose}>
      <Form />
    </SidePanel>
  );
};
