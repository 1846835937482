import { useCallback, useEffect, useState } from 'react';

import { fontSpy } from '../utils';
import { isBrowser } from '../utils/is-browser';
import { useBreakpoint } from './useBreakpoint';

const styles =
  isBrowser()
    ? getComputedStyle(document.documentElement)
    : undefined;
const fontFamilyBody =
  isBrowser()
    ? styles?.getPropertyValue('--font-family-body') || ''
    : '';

interface IUseItemsMoreButtonProps {
  containerClassName: string;
  itemClassName: string;
  moreContainerClassName: string;
  breakpoints?: string[];
}

type IUseItemsMoreButtonReturn = {
  isInit: boolean;
  isOpen: boolean;
  handleToggle: () => void;
  handleClose: () => void;
};

type IUseItemsMoreButton = (
  props: IUseItemsMoreButtonProps
) => IUseItemsMoreButtonReturn;

export const useItemsMoreButton: IUseItemsMoreButton = ({
  containerClassName,
  itemClassName,
  moreContainerClassName,
  breakpoints = [],
}) => {
  const [state, setState] = useState({
    isInit: false,
    isOpen: false,
  });

  const { breakpoint } = useBreakpoint();

  const handleToggle = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isOpen: !prevState.isOpen,
    }));
  }, []);

  const handleClose = useCallback(() => {
    setState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }, []);

  const calculateItemsVisibility = useCallback(() => {
    const container = document.querySelector(`.${containerClassName}`);
    const moreContainer = container?.querySelector(
      `.${moreContainerClassName}`
    );
    const allItems = container ? Array.from(container.children) : [];
    const primaryItems = allItems.filter(
      (el) => !el.classList.contains(moreContainerClassName)
    );
    const secondaryItems = moreContainer?.querySelectorAll(`.${itemClassName}`);

    // reveal all items for the calculation
    allItems.forEach((item) => {
      item.classList.remove('hidden');
    });

    // hide items that won't fit in the Primary
    const primaryWidth = container?.getBoundingClientRect().width || 0;
    let stopWidth = moreContainer?.getBoundingClientRect()?.width || 0;
    const hiddenItems: number[] = [];

    primaryItems.forEach((item, i) => {
      if (primaryWidth >= stopWidth + item.getBoundingClientRect().width) {
        stopWidth += item.getBoundingClientRect().width;
      } else {
        item.classList.add('hidden');
        hiddenItems.push(i);
      }
    });

    // toggle the visibility of More button and items in Secondary
    if (!hiddenItems.length) {
      moreContainer?.classList.add('hidden');
    } else {
      moreContainer?.classList.remove('hidden');
      secondaryItems?.forEach((item, i) => {
        item.classList.toggle('hidden', !hiddenItems.includes(i));
      });
    }

    setState((prevState) => ({
      ...prevState,
      isInit: true,
    }));
  }, []);

  const hideSecondaryItems = useCallback(
    ({ target }) => {
      if (!target.closest(`.${moreContainerClassName}`)) {
        handleClose();
      }
    },
    [handleClose]
  );

  useEffect(() => {
    if (breakpoints.includes(breakpoint as string)) {
      // adapt immediately on load
      fontSpy(fontFamilyBody, () => {
        calculateItemsVisibility();
      });
    }
  }, [breakpoints, breakpoint, calculateItemsVisibility]);

  useEffect(() => {
    if (isBrowser()) {
      // hide Secondary on the outside click
      document.addEventListener('click', hideSecondaryItems);
    }

    return () => {
      if (isBrowser()) {
        document.removeEventListener('click', hideSecondaryItems);
      }
    };
  }, [calculateItemsVisibility]);

  return { ...state, handleToggle, handleClose };
};
