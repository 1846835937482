import { forwardRef, useEffect, useRef, VideoHTMLAttributes } from 'react';

import { useCombinedRefs, useVideoBg } from '../../hooks';
import { IVideoMap, videoMap } from './videoMap';

interface IVideoProps extends VideoHTMLAttributes<HTMLVideoElement> {
  name: keyof IVideoMap;
  onGetVideoBgColor: (color: string) => void;
  onLoadMetadata?: (event: Event) => void;
  specifiedColor?: string;
}

export const Video = forwardRef<HTMLVideoElement, IVideoProps>((props, ref) => {
  const { name, onGetVideoBgColor, onLoadMetadata, specifiedColor, ...rest } =
    props;
  const src = videoMap?.[name] || '';
  const videoRef = useRef<HTMLVideoElement>(null);
  const combinedRef = useCombinedRefs<HTMLVideoElement>(videoRef, ref);

  useVideoBg<HTMLVideoElement>(combinedRef, onGetVideoBgColor, specifiedColor);

  useEffect(() => {
    if (combinedRef?.current && onLoadMetadata) {
      combinedRef.current.onloadedmetadata = onLoadMetadata;
    }
  }, []);

  return (
    <video ref={combinedRef} {...rest}>
      <source src={src} type="video/mp4" />
    </video>
  );
});
