import classNames from 'classnames';
import { motion } from 'framer-motion';
import { FC, ReactNode, useCallback, useState } from 'react';

import { SvgIcon } from '../SVGIcon';

interface IAccordionProps {
  className?: string;
  title?: ReactNode;
  titleClassName?: string;
  iconClassName?: string;
  panelClassName?: string;
  expanded?: boolean;
}

export const Accordion: FC<IAccordionProps> = ({
  className,
  title,
  titleClassName,
  iconClassName,
  panelClassName,
  children,
  expanded = false,
}) => {
  const [isExpanded, setExpanded] = useState(expanded);

  const handleClick = useCallback(() => {
    setExpanded((prevState) => !prevState);
  }, []);

  return (
    <div className={className}>
      <div
        className={classNames(
          titleClassName,
          'flex',
          'items-center',
          'space-x-[8px]',
          'font-bold',
          'cursor-pointer'
        )}
        onClick={handleClick}
      >
        <div
          className={classNames(
            iconClassName,
            'text-orange-60',
            'transition-transform',
            'transform',
            !isExpanded && 'rotate-180'
          )}
        >
          <SvgIcon name="arrow_up" />
        </div>
        <div>{title}</div>
      </div>
      <motion.div
        initial="collapsed"
        animate={isExpanded ? 'open' : 'collapsed'}
        variants={{
          open: { opacity: 1, scale: 1, height: 'auto' },
          collapsed: { opacity: 0, scale: 0.9, height: 0 },
        }}
        className={classNames('overflow-hidden', panelClassName)}
      >
        {children}
      </motion.div>
    </div>
  );
};
