import classNames from 'classnames';
import { FC } from 'react';
import { Trans } from 'react-i18next';

import { useTranslateData } from '../../hooks';
import { Link } from '../../ui';
import { checkLinkSpecialCase } from '../../utils/checkLinkSpecialCase';
import { Image } from '../Image';
import { SvgIcon } from '../SVGIcon';

type IRegistrationData = {
  value: string;
};

type IContactsData = {
  phone: {
    value: string;
    url: string;
  };
  press: {
    label: string;
    value: string;
    url: string;
  };
  questions: {
    label: string;
    value: string;
    url: string;
  };
};

type INavigationData = {
  title: string;
  children: Partial<{ label: string; url: string; image: string, note: boolean, text: string }>[];
}[];

export const Footer: FC = (props) => {
  const registration = useTranslateData<IRegistrationData>(
    'registration'
  ) as IRegistrationData;
  const contacts = useTranslateData<IContactsData>('contacts') as IContactsData;
  const navigation = useTranslateData<INavigationData>(
    'navigation'
  ) as INavigationData;

  return (
    <footer {...props}>
      <div
        className={classNames(
          'container',
          'text-[14px]',
          'lg:text-[16px]',
          'leading-[18px]',
          'lg:leading-[20px]',
          'pb-[11px]',
          'xs:pb-[19px]',
          'lg:pb-[45px]'
        )}
      >
        <hr
          className={classNames(
            'border-[#E6E6E6]',
            'mb-[30px]',
            'sm:mb-[36px]'
          )}
        />
        <div className={classNames('sm:flex', 'sm:justify-between', 'gap-x-[40px]')}>
          <div className={classNames('sm:block', 'mb-[25px]', 'sm:mb-0')}>
            <div>
              <SvgIcon
                name="logo"
                className={classNames(
                  'w-[154px]',
                  'h-[20px]',
                  'sm:w-[161px]',
                  'lg:w-[200px]',
                  'lg:h-[26px]',
                  'mb-[6px]'
                )}
              />
              <div
                className={classNames(
                  'text-graphite-70',
                  'mb-[25px]',
                  'lg:text-[14px]',
                  'lg:leading-[18px]',
                  'max-w-[230px]'
                )}
              >
                <Trans>{registration?.value}</Trans>
              </div>
            </div>
            <div className={classNames('sm:block')}>
              <div
                className={classNames(
                  'font-bold',
                  'mb-[14px]',
                  'tracking-[-0.48px]'
                )}
              >
                <Link to={contacts?.phone?.url}>
                  <Trans>{contacts?.phone?.value}</Trans>
                </Link>
              </div>
              <div className="mb-[14px]">
                <div className="mb-[3px]">
                  <Trans>{contacts?.press?.label}</Trans>
                </div>
                <Link to={contacts?.press?.url}>
                  <Trans>{contacts?.press?.value}</Trans>
                </Link>
              </div>
              <div>
                <div className="mb-[3px]">
                  <Trans>{contacts?.questions?.label}</Trans>
                </div>
                <Link to={contacts?.questions?.url}>
                  <Trans>{contacts?.questions?.value}</Trans>
                </Link>
              </div>
            </div>
          </div>

          <div
            className={classNames(
              'grid',
              'gap-y-[25px]',
              'sm:grid-cols-2',
              'sm:gap-x-[60px]',
              'sm:gap-y-[25px]',
              'lg:grid-cols-3',
              'xl:gap-x-[125px]'
            )}
          >
            {navigation?.map(({ title, children }) => (
              <div key={title}>
                <div className={classNames('font-bold', 'mb-[13px]')}>
                  <Trans>{title}</Trans>
                </div>
                {children && (
                  <ul className="space-y-[12px]">
                    {children.map(({ label, url, image, note, text }) => (
                      <li
                        key={label || image || url}
                        className={note ? classNames('text-[14px]', 'text-graphite-70', 'leading-[18px]') : ''}
                      >
                        {text && <Trans>{text}</Trans>}
                        <Link to={url || ''} specialCase={checkLinkSpecialCase(url || '')}>
                          {label && <Trans>{label}</Trans>}
                          {image && (
                            <Image className={classNames(
                              'w-[85px]',
                              'md:w-[100px]'
                            )} src={image} alt={label || ''} />
                          )}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};
