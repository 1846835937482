import { I18nextProvider } from "react-i18next";

import i18n from "./src/locales";
import { Provider } from "./src/store";

export function wrapRootElement({ element }) {
  return (
    <I18nextProvider i18n={i18n}>
      <Provider>{element}</Provider>
    </I18nextProvider>
  );
}
