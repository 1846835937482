import { useUserAgent } from '@oieduardorabelo/use-user-agent';
import { useAction } from '@reatom/react';
import classNames from 'classnames';
import {
  AnimatePresence,
  motion,
  useTransform,
  useViewportScroll,
} from 'framer-motion';
import { FC, useCallback, useEffect, useRef, useState } from 'react';

import { requestPanelActions, SvgIcon, Trans } from '../';
import { useScrollDirection, useTranslateData } from '../../hooks';
import { Button, Link } from '../../ui';
import { isBrowser } from '../../utils/is-browser';
import { DesktopNavigation } from './DesktopNavigation';
import { LocaleToggler } from './Locale';
import { Navigation } from './Navigation';
import * as styles from './styles.module.css';

type IControlsData = {
  request: string;
  demo: string;
  determineValue: string;
  more: string;
};

type IContactsData = {
  phone: {
    value: string;
    url: string;
  };
  press: {
    label: string;
    value: string;
    url: string;
  };
  questions: {
    label: string;
    value: string;
    url: string;
  };
};

type IHeaderProps = {
  className?: string;
  location: Location;
};

const BROWSER_SPECIAL_CASE = 'Safari';

export const Header: FC<IHeaderProps> = ({ className, children, location }) => {
  const [isMenuVisible, setMenuVisibility] = useState(false);
  const { request } = useTranslateData<IControlsData>(
    'controls'
  ) as IControlsData;
  const { phone, press, questions } = useTranslateData<IContactsData>(
    'contacts'
  ) as IContactsData;
  const headerRef = useRef<HTMLElement>(null);
  const { scrollY } = useViewportScroll();
  const direction = useScrollDirection(0.05);
  const userAgent = useUserAgent(
    isBrowser() ? undefined : ''
  );

  const transformY = useTransform(scrollY, (scrollY) => {
    const headerEl = headerRef.current;

    if (!headerEl || direction === 'up') {
      return 0;
    }

    const { height } = headerEl.getBoundingClientRect();

    if (scrollY > height) {
      return '-100%';
    }

    return 0;
  });

  const top = useTransform(scrollY, (scrollY) => {
    const headerEl = headerRef.current;

    if (!headerEl) {
      return 'auto';
    }

    const { height } = headerEl.getBoundingClientRect();

    if (scrollY > height * 2) {
      return 0;
    }

    return 'auto';
  });

  const openRequestPanelAction = useAction(
    requestPanelActions.openRequestPanelAction
  );

  const handleOpenRequestPanel = useCallback(() => {
    openRequestPanelAction();
  }, [openRequestPanelAction]);

  const handleMenuToggle = useCallback(() => {
    setMenuVisibility((prevState) => !prevState);
  }, []);

  const handleMenuClose = useCallback(() => {
    setMenuVisibility(() => false);
  }, []);

  const scrollToAnchor = useCallback(
    (hash: string) => {
      const targetEl = document.getElementById(hash);
      if (userAgent?.browser.name === BROWSER_SPECIAL_CASE) {
        setTimeout(() => targetEl?.scrollIntoView(), 0);
      } else {
        targetEl?.scrollIntoView();
      }
      history.replaceState({ key: history.state.key }, '');
    },
    [userAgent?.browser.name]
  );

  useEffect(() => {
    if (history.state?.hash) {
      scrollToAnchor(history.state?.hash);
    }
  }, [scrollToAnchor, location.pathname]);

  return (
    <>
      <AnimatePresence>
        {isMenuVisible && (
          <motion.div
            onClick={handleMenuClose}
            className={classNames(
              'fixed',
              'top-0',
              'bottom-0',
              'left-0',
              'right-0',
              'bg-black',
              'bg-opacity-[0.48]',
              'z-10',
              'sm:hidden'
            )}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          />
        )}
      </AnimatePresence>

      <motion.header
        ref={headerRef}
        className={classNames(
          className,
          'sticky',
          'z-10',
          'bg-page',
          'transform',
          'transition-transform'
        )}
        // @ts-ignore
        style={{ '--tw-translate-y': transformY, top }}
      >
        <div
          className={classNames(
            'sm:flex',
            'sm:container',
            'sm:justify-between'
          )}
        >
          <div
            className={classNames(
              'container',
              'relative',
              'z-10',
              'sm:z-auto',
              'flex',
              'bg-page',
              'flex-row',
              'items-center',
              'justify-between',
              'h-[60px]',
              'lg:h-[67px]',
              'border-b-1',
              'border-solid',
              'border-graphite-70',
              'transition-colors',
              isMenuVisible ? 'border-opacity-20' : 'border-opacity-0',
              'sm:border-opacity-0',
              // "sm:flex-1",
              'sm:px-[0px]',
              'sm:mx-[0px]',
              'sm:w-auto'
            )}
          >
            <Link to="/">
              <SvgIcon
                name="logo"
                className={classNames(
                  'w-[145px]',
                  'sm:w-[161px]',
                  'sm:h-[20px]',
                  'lg:w-[180px]',
                  'lg:h-[22px]',
                  'xl:w-[225px]',
                  'xl:h-[28px]'
                )}
              />
            </Link>
            <div
              className={classNames(
                'w-[28px]',
                'h-[28px]',
                'sm:hidden',
                'flex',
                'justify-center',
                'items-center'
              )}
              onClick={handleMenuToggle}
            >
              <SvgIcon name={isMenuVisible ? 'close' : 'menu_closed'} />
            </div>
          </div>

          <div
            className={classNames(
              'xl:max-h-full',
              'fixed',
              'sm:relative',
              'top-[60px]',
              'sm:top-0',
              'left-0',
              'sm:left-auto',
              'right-0',
              'overscroll-contain',
              'overflow-y-auto',
              'sm:overflow-visible',
              'pt-[12px]',
              'pb-[14px]',
              'bg-page',
              'transition-transform',
              'sm:transition-none',
              'transform',
              isMenuVisible
                ? 'translate-y-0'
                : 'translate-y-[calc(-100%-60px)]',
              'sm:translate-y-0',
              'sm:w-[calc(100%-161px-15px)]',
              'sm:flex',
              'sm:items-center'
            )}
          >
            <div
              className={classNames(
                'container',
                'sm:flex',
                'sm:flex-row',
                'sm:items-center',
                'sm:space-x-[10px]',
                'sm:px-[0px]',
                'sm:mx-[0px]',
                'sm:justify-end'
              )}
            >
              <AnimatePresence>
                {isMenuVisible && (
                  <motion.div
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 1 }}
                    className={classNames('sm:hidden')}
                  >
                    <Navigation onItemClick={handleMenuClose} />
                  </motion.div>
                )}
              </AnimatePresence>
              <div
                className={classNames(
                  'hidden',
                  'sm:block',
                  'sm:w-[calc(100%-150px-15px)]'
                )}
              >
                <DesktopNavigation />
              </div>
              <Button
                className={classNames('w-full', 'sm:w-auto', styles.button)}
                onClick={handleOpenRequestPanel}
                name="openRequestPanelFromHeader"
              >
                <Trans>{request}</Trans>
              </Button>
              <LocaleToggler onItemClick={handleMenuClose} />
              <div className={classNames('mt-[20px]', 'sm:hidden')}>
                <div className="font-bold">
                  <Link to={phone?.url}>
                    <Trans>{phone?.value}</Trans>
                  </Link>
                </div>
                <div className={classNames('space-y-[12px]', 'mt-[20px]')}>
                  <div>
                    <Trans>{press?.label}</Trans>
                    <br />
                    <Link to={press?.url}>
                      <Trans>{press?.value}</Trans>
                    </Link>
                  </div>
                  <div>
                    <Trans>{questions?.label}</Trans>
                    <br />
                    <Link to={questions?.url}>
                      <Trans>{questions?.value}</Trans>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {children}
      </motion.header>
    </>
  );
};
