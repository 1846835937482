import classNames from 'classnames';
import { FC } from 'react';

import { useTranslateData } from '../../../hooks';
import { Link } from '../../../ui';
import { checkLinkSpecialCase } from '../../../utils/checkLinkSpecialCase';
import { Trans } from '../../Trans';
import * as styles from './styles.module.css';

interface INavigationProps {
  onItemClick: () => void;
  className?: string;
}

interface INavigationItem<T = INavigationChildren> {
  title: string;
  children: T[];
}

type INavigationChildren = ILabelUrl | ILabel | IImageUrl;

type ILabelUrl = { label: string; url: string };

type ILabel = { label: string };

type IImageUrl = { image: string; label: string };

type INavigationData = [
  INavigationItem<ILabelUrl>,
  INavigationItem<ILabelUrl>,
  INavigationItem<ILabel | IImageUrl>
];

export const Navigation: FC<INavigationProps> = ({
  className,
  onItemClick,
  ...restProps
}) => {
  const navigation = useTranslateData<INavigationData>(
    'navigation'
  ) as INavigationData;

  return (
    <nav
      className={classNames(
        className,
        'mb-[18px]',
        'mt-[18px]',
        'sm:mb-0',
        'sm:flex',
        'sm:items-center',
        'sm:space-x-[30px]'
      )}
      {...restProps}
    >
      {navigation[0].children.map(({ label, url }) => (
        <div className={styles.navItem} key={label || url}>
          <span onClick={onItemClick}>
            <Link to={url} specialCase={checkLinkSpecialCase(url)}>
              {label && <Trans>{label}</Trans>}
            </Link>
          </span>
        </div>
      ))}
    </nav>
  );
};
