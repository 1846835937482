import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import { FC } from 'react';
import { createPortal } from 'react-dom';

import { SvgIcon } from '../SVGIcon';

// Use a ternary operator to make sure that the document object is defined
const portalRoot = typeof document !== `undefined` ? document.body : null;

export const SidePanel: FC<{ isOpen?: boolean; handleClose?: () => void }> = ({
  isOpen,
  handleClose,
  children,
}) => {
  if (!portalRoot) {
    return null;
  }
  return createPortal(
    <>
      <AnimatePresence>
        {isOpen && (
          <>
            <motion.div
              onClick={handleClose}
              className={classNames(
                'fixed',
                'z-50',
                'top-0',
                'bottom-0',
                'left-0',
                'right-0',
                'bg-black',
                'bg-opacity-[0.48]'
              )}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            />
            <motion.div
              className={classNames(
                'bg-white',
                'fixed',
                'z-50',
                'top-0',
                'bottom-0',
                'right-0',
                'w-full',
                'xs:max-w-[570px]',
                'overflow-auto'
              )}
              initial={{ opacity: 0, x: '100%' }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: '100%' }}
            >
              <div
                className={classNames(
                  'text-graphite-70',
                  'sticky',
                  'top-[14px]',
                  'right-[14px]',
                  'float-right'
                )}
              >
                <button className="focus:outline-none" onClick={handleClose} name="closeRequestPanel">
                  <SvgIcon name="close" />
                </button>
              </div>
              <div
                className={classNames(
                  'px-[16px]',
                  'lg:px-[50px]',
                  'text-graphite-90',
                  'pt-[66px]',
                  'pb-[20px]'
                )}
              >
                {children}
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </>,
    portalRoot
  );
};
