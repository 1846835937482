import classNames from 'classnames';
import { FC, useMemo } from 'react';

import { useItemsMoreButton, useTranslateData } from '../../../hooks';
import { breakpoints as commonBreakpoints } from '../../../hooks/useBreakpoint';
import { Link } from '../../../ui';
import { checkLinkSpecialCase } from '../../../utils/checkLinkSpecialCase';
import { Trans } from '../../Trans';
import * as styles from './styles.module.css';

type IHeaderNavigationData = { label: string; url: string }[];

type IControlsData = {
  request: string;
  demo: string;
  determineValue: string;
  more: string;
};

const breakpoints = Object.keys(commonBreakpoints);
export const DesktopNavigation: FC = () => {
  const headerDesktopNavigation = useTranslateData<IHeaderNavigationData>(
    'headerDesktopNavigation'
  ) as IHeaderNavigationData;
  const { more } = useTranslateData<IControlsData>('controls') as IControlsData;
  const itemClassName = classNames('whitespace-nowrap', 'relative');

  const { isOpen, isInit, handleToggle, handleClose } = useItemsMoreButton({
    containerClassName: styles.container,
    itemClassName: styles.item,
    moreContainerClassName: styles.moreContainer,
    breakpoints,
  });

  const items = useMemo(() => {
    return headerDesktopNavigation.map(({ label, url }) => {
      return (
        <li key={label} className={classNames(itemClassName, styles.item)}>
          <span onClick={handleClose}>
            <Link
              to={url}
              className={classNames('px-[10px]', 'md:px-[15px]', 'py-[11px]', 'block')}
              specialCase={checkLinkSpecialCase(url)}
            >
              <Trans>{label}</Trans>
            </Link>
          </span>
        </li>
      );
    });
  }, [headerDesktopNavigation, handleClose]);

  return (
    <ul
      className={classNames(
        'flex',
        'justify-end',
        'text-[14px]',
        'leading-[16px]',
        'transition-opacity',
        isInit ? 'opacity-100' : 'opacity-0',
        styles.container
      )}
    >
      {items}
      <li
        className={classNames(
          itemClassName,
          styles.moreContainer,
          isOpen && styles.moreContainerOpen
        )}
      >
        <span
          className={classNames(
            'focus:outline-none',
            'px-[15px]',
            'py-[11px]',
            'block',
            'hover:underline',
            'cursor-pointer'
          )}
          onClick={handleToggle}
        >
          {more}…
        </span>
        <ul
          className={classNames(
            styles.moreList,
            'absolute',
            'top-full',
            'right-0',
            'rounded-l-[4px]',
            'rounded-br-[4px]',
            'bg-page',
            !isOpen && 'hidden'
          )}
        >
          {items}
        </ul>
      </li>
    </ul>
  );
};
