import useBreakpointHook, { Breakpoint, Config } from 'use-breakpoint';

export const breakpoints = {
  xs: 480,
  sm: 768,
  md: 1024,
  lg: 1280,
  xl: 1680
};

const BREAKPOINTS: Config = {
  default: 0,
  ...breakpoints
};

export const useBreakpoint = (): Breakpoint<Config> => {
  return useBreakpointHook(BREAKPOINTS, 'default');
};
