import { useAction, useAtom } from '@reatom/react';
import classNames from 'classnames';
import { FC, useCallback } from 'react';

import { useTranslateData } from '../../hooks';
import { SidePanel, Trans } from '../index';
import * as actions from './actions';
import * as atoms from './atoms';
import * as styles from './styles.module.css';

export const TypicalDocumentsPanel: FC = () => {
  const { isOpen } = useAtom(atoms.requestPanelAtom);
  const closeAction = useAction(actions.closeTypicalDocumentsPanelAction);
  const { title, list } = useTranslateData('components.typicalDocumentsPanel');

  const handleClose = useCallback(() => {
    closeAction();
  }, [closeAction]);

  return (
    <SidePanel isOpen={isOpen} handleClose={handleClose}>
      <div
        className={classNames(
          'font-bold',
          'text-[23px]',
          'leading-[28px]',
          'tracking-[-0.6px]',
          'lg:text-[40px]',
          'lg:leading-[49px]',
          'lg:tracking-[-1.3px]',
          'mb-[8px]'
        )}
      >
        <Trans>{title}</Trans>
      </div>
      <div
        className={classNames(
          'text-[16px]',
          'leading-[20px]',
          'tracking-[-0.2px]',
          'lg:mx-[-26px]'
        )}
      >
        {list.map(({ label, children }) => (
          <div
            key={label}
            className={classNames(
              styles.item,
              'lg:w-1/2',
              'lg:overflow-hidden',
              'lg:px-[26px]',
              'pt-[24px]'
            )}
          >
            <div className={classNames('font-bold', 'mb-[14px]')}>
              <Trans>{label}</Trans>
            </div>
            <ul
              className={classNames(
                'list-outside',
                'list-square',
                'space-y-[14px]',
                'pl-[18px]',
                'text-orange-60'
              )}
            >
              {children.map((item) => (
                <li key={item}>
                  <div className="text-graphite-90">
                    <Trans>{item}</Trans>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </SidePanel>
  );
};
