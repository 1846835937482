import classNames from 'classnames';
import { ButtonHTMLAttributes, FC } from 'react';

interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  colored?: boolean;
  disabled?: boolean;
}

export const Button: FC<IButtonProps> = ({
  className,
  colored = true,
  children,
  disabled,
  ...props
}) => {
  return (
    <button
      className={classNames(
        className,
        colored && ['bg-orange-60', 'hover:bg-orange-65', 'focus:bg-orange-75'],
        'border-[1px]',
        'border-orange-60',
        'hover:border-orange-65',
        'focus:border-orange-75',
        !colored && [
          'sm:border-opacity-0',
          'sm:hover:border-opacity-0',
          'sm:focus:border-opacity-0',
        ],
        'border-solid',
        'rounded-[6px]',
        'h-[35px]',
        'sm:h-[50px]',
        'px-[18px]',
        'sm:px-[20px]',
        colored
          ? 'text-white'
          : ['text-orange-60', 'hover:text-orange-65', 'focus:text-orange-65'],
        'whitespace-nowrap',
        'text-[14px]',
        'sm:text-[18px]',
        'leading-[18px]',
        'sm:leading-[21px]',
        'transition-colors',
        'focus:outline-none',
        'tracking-[-0.3px]',
        'sm:tracking-[-0.38px]',
        'filter',
        disabled && ['grayscale-[0.5]', 'cursor-wait']
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
