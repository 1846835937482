import { useThrottleCallback } from '@react-hook/throttle';
import { transform, useTransform, useViewportScroll } from 'framer-motion';
import { useCallback, useRef, useState } from 'react';

export const useScrollDirection = (minRange = 0): 'up' | 'down' | null => {
  const { scrollYProgress } = useViewportScroll();
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down' | null>(
    null
  );
  const prevValueRef = useRef(0);

  const handleScrollDirection = useCallback(
    useThrottleCallback((scrollY) => {
      const prevOffset = prevValueRef.current;
      const valueAsPercent = transform(scrollY, [minRange, 1], [0, 100]);

      if (valueAsPercent === 0) {
        setScrollDirection(null);
      }

      if (valueAsPercent > prevOffset) {
        if (scrollDirection !== 'down') {
          setScrollDirection('down');
        }
      } else if (valueAsPercent < prevOffset) {
        if (scrollDirection !== 'up') {
          setScrollDirection('up');
        }
      }

      prevValueRef.current = valueAsPercent;
    }, 5),
    []
  );

  useTransform(scrollYProgress, handleScrollDirection);

  return scrollDirection;
};
