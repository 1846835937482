import classNames from 'classnames';
import { FC } from 'react';

import { iconMap } from './iconMap';

interface ISVGIconProps {
  name: string;
  className?: string;
  fill?: boolean
}

export const SvgIcon: FC<ISVGIconProps> = ({ name, className, fill = true, ...props }) => {
  const C = iconMap?.[name] || 'span';

  return <C className={classNames(className, fill ? 'fill-current' : '')} {...props} />;
};
