import { declareAtom } from '@reatom/core';

import {
  closeTypicalDocumentsPanelAction,
  openTypicalDocumentsPanelAction,
} from './actions';
import { NAME } from './contstants';

const initialState = { isOpen: false };

export const requestPanelAtom = declareAtom(
  `${NAME}Atom`,
  initialState,
  (on) => [
    on(openTypicalDocumentsPanelAction, (state) => {
      return {
        ...state,

        isOpen: true,
      };
    }),
    on(closeTypicalDocumentsPanelAction, () => initialState),
  ]
);
