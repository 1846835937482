import { FC, useEffect, useState } from 'react';

import i18n, { LOCALES } from '../../locales';
import * as styles from './styles.module.css';

interface ILocaleToggler {
  onItemClick?: () => void
}
export const LocaleToggler: FC<ILocaleToggler> = ({ onItemClick }) => {
  const [lang, setLang] = useState(i18n.language);

  const selectLng = (event:React.SyntheticEvent<HTMLLIElement>) => {
    const elem = event.target as HTMLLIElement;
    setLang(elem.dataset.lang || LOCALES.en);
    onItemClick?.();
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang]);

  return (
    <ul className={styles.locales}>
      <li
        onClick={selectLng}
        data-lang={LOCALES.ru}
        className={lang === LOCALES.ru ? styles.selectedLng : null}>
          {LOCALES.ru}
      </li>
      <li
        onClick={selectLng}
        data-lang={LOCALES.en}
        className={lang === LOCALES.en ? styles.selectedLng : null}>
          {LOCALES.en}
      </li>
    </ul>
  );
};
